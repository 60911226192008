<template>
  <div class="history-order-ticket">
    <div v-for="(ticket, index) in ticket" :key="index">
      <a-row class="history-order-list" type="flex">
        <a-col :flex="3"
          >{{ ticket.product_quantity }} x {{ ticket.product.name }}</a-col
        >
        <a-col class="order-price" :flex="3"
          >${{ getPricing(ticket) }}</a-col
        >
      </a-row>
      <div
        class="history-extra"
        v-for="optional in ticket.extraGroups"
        :key="optional.description"
      >
        {{ optional.description }}
        <a-row
          v-for="extra in optional.extras"
          :key="extra.name"
          class="history-order-list extra"
          type="flex"
        >
          <a-col :flex="3">{{ extra.name }}</a-col>
          <a-col class="order-price" :flex="3">{{ getExtraCost(extra) }}</a-col>
        </a-row>
      </div>
    </div>
  </div>
</template>
<script>
import { formatNumbers } from "@/utils/utils.js";

export default {
  name: "Extras",
  props: {
    isXpressPriceQr: null,
    orderTickets: null,
  },
  data() {
    return {
      ticket: null,
    };
  },
  methods: {
    getExtraCost(extra) {
      let extraCost = extra.cost;
      if (this.isXpressPriceQr) {
        extraCost = formatNumbers.getExtraCost(extra, this.isXpressPriceQr);
      }
      return extraCost ? `$${extraCost}` : "--";
    },
    getPricing(ticket) {
      const product = ticket.product;
      if (this.isXpressPriceQr) {
        if (product.qr_pricing !== null) {
          return product.qr_pricing;
        }
        return ticket.subtotal / ticket.product_quantity;
      }
      return product.pricing;
    }
  },
  mounted() {
    if (this.orderTickets) {
      this.ticket = this.orderTickets.map((ticket) => {
        const extraGroup = {};
        for (const key in ticket.extra_relations) {
          const _extra = ticket.extra_relations[key].extra;
          const extraGroupId = _extra.extra_group.id;
          if (!extraGroup[extraGroupId]) {
            extraGroup[extraGroupId] = {
              description:
                _extra.extra_group.description,
              extras: [],
            };
          }
          extraGroup[extraGroupId].extras.push({
            name: _extra.name,
            cost: +_extra.cost,
            qr_cost: _extra.qr_cost
          });
        }
        return {
          ...ticket,
          extraGroups: Object.values(extraGroup),
        };
      });
    }
  },
};
</script>
